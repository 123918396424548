
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ColumnData, SignOffHistoryItem, Ticket } from '@/types/om27.types';
import { DateRenderOptions, getDateRenderer } from '@/utils/datetime';
import IconMenu from '@/components/gsk-components/menu/IconMenu.vue';
import { RouteNames } from '@/constants';
import OverflowText from '@/components/OverflowText.vue';
import BlockingTickets from '@/components/BlockingTickets.vue';
import TablePagination from '@/components/table/TablePagination.vue';

type HistoryColumData = ColumnData<SignOffHistoryItem & { actions: unknown }>;

@Component({
  components: {
    IconMenu,
    OverflowText,
    BlockingTickets,
    TablePagination
  },
})
export default class SignOffHistoryGrid extends Vue {
  @Prop({ required: true, type: Array }) signOffHistory!: SignOffHistoryItem[];
  @Prop({ required: true, type: String }) timeZone!: string;
  @Prop({ type: Boolean }) dense!: boolean;
  @Prop({ type: Boolean }) paginate!: boolean;
  @Prop({ type: Boolean }) showAll!: boolean;

  page = 1;
  perPage = 25;

  get logicalBotId(): number {
    return parseInt(this.$route.params.logicalBotId, 10);
  }

  get scrollWidth() {
    return '2400px';
  }

  get rows() {
    let start = (this.page - 1) * this.perPage;
    if (start > this.signOffHistory.length) {
      this.page = Math.ceil(this.signOffHistory.length / this.perPage);
      start = (this.page - 1) * this.perPage;
    }
    const end = start + this.perPage;
    return this.signOffHistory.slice(start, end);
  }

  dateCell(
    key: keyof SignOffHistoryItem,
    title: string,
    opts?: { fixed?: boolean },
  ): HistoryColumData {
    const fixed = opts?.fixed ? 'left' : undefined;
    return {
      field: key,
      title,
      renderBodyCell: this.getDateRenderer(key),
      width: 180,
      fixed,
    };
  }

  cell(
    key: HistoryColumData['field'],
    title: string,
    opts?: { fixed?: boolean; width?: number },
  ): HistoryColumData {
    const fixed = opts?.fixed ? 'left' : undefined;
    return {
      field: key,
      title,
      width: opts?.width ?? 120,
      fixed,
    };
  }

  popoverCell(
    key: HistoryColumData['field'],
    title: string,
    opts?: { width?: number },
  ): HistoryColumData {
    return {
      field: key,
      title,
      renderBodyCell: ({ row, column }, h) => {
        return (
          <OverflowText
            props={{ text: row[key] ?? '—', length: 80, label: column.title, breakNewlines: true }}
          />
        );
      },
      width: opts?.width ?? 290,
    };
  }

  ticketCell(
    key: HistoryColumData['field'],
    title: string,
    tickets: (row: SignOffHistoryItem) => Partial<Ticket>[],
    opts?: { width?: number },
  ): HistoryColumData {
    return {
      field: key,
      title,
      width: opts?.width ?? 150,
      renderBodyCell: ({ row }, h) => {
        return (
          <BlockingTickets props={{ tickets: tickets(row) }} />
        );
      }
    };
  }

  isTicket(ticket: Partial<Ticket>) {
    return ticket && ticket.ticketNumber;
  }

  get columns(): HistoryColumData[] {
    const cols: HistoryColumData[] = [
      {
        field: 'signofffordesc',
        title: 'Sign Off Date',
        width: 180,
        fixed: 'left',
      },
      {
        field: 'actions',
        title: 'Actions',
        fixed: 'left',
        width: 160,
        renderBodyCell: ({ row }, h) => {
          return (
            <router-link
              to={{
                name: RouteNames.RpaOpsSignOffDetails,
                params: {
                  logicalBotId: this.logicalBotId,
                  signOffId: row.signoffId,
                },
                query: this.$route.query,
              }}
              class="f-text-button--primary"
            >
              Sign Off Details
            </router-link>
          );
        },
      },
      this.ticketCell('blockingTickets', 'Tickets', (row) => row.tickets),
      {
        field: 'signoffHumanUserMudId',
        title: 'Signed Off User',
        width: 120,
        renderBodyCell: ({ row }, h) => {
          const { signoffHumanUserName: name, signoffHumanUserMudId: mudId } = row;
          if (name || mudId) {
            return (
              <span>
                {name} ({mudId})
              </span>
            );
          }
          return <span>—</span>;
        },
      },
      this.cell('signoffId', 'Sign Off ID'),
      this.dateCell('signoffStartDate', 'Sign Off Started'),
      this.dateCell('signoffNeededByDate', 'Sign Off Needed By'),
      this.dateCell('signoffEffectiveDate', 'Effective Date'),
      this.dateCell('closureDate', 'Sign Off Closure'),
      {
        field: 'verifyHumanUserMudId',
        title: 'Verifer',
        width: 120,
        renderBodyCell: ({ row }, h) => {
          const { verifyHumanUserName: name, verifyHumanUserMudId: mudId } = row;
          if (name || mudId) {
            return (
              <span>
                {name} ({mudId})
              </span>
            );
          }
          return <span>—</span>;
        },
      },
      this.dateCell('lastVerificationDate', 'Last Verification'),
      this.cell('lastVerificationStatus', 'Last Verification Status'),
      this.cell('lastVerificationStatusCode', 'Last Verification Status Code'),
      this.ticketCell(
        'verifyTicket',
        'Verification Ticket',
        (row) => this.isTicket(row.verifyTicket) ? [row.verifyTicket] : [],
        { width: 120 },
      ),
      this.popoverCell('lastVerificationNotes', 'Verification Notes'),
      this.cell('evidenceType', 'Evidence Type', { width: 150 }),
      this.ticketCell(
        'signoffTicket',
        'Sign Off Ticket Number',
        (row) => this.isTicket(row.signoffTicket) ? [row.signoffTicket] : [],
        { width: 120 },
      ),
      this.popoverCell('evidenceDetails', 'Sign Off Notes'),
    ];

    return cols.map(col => {
      return { align: 'left', key: col.field, ...col };
    });
  }

  getDateRenderer(
    field: keyof SignOffHistoryItem,
    info: keyof SignOffHistoryItem | '' = '',
    format: 'date' | 'time' | '' = '',
  ): HistoryColumData['renderBodyCell'] {
    let opts: DateRenderOptions = {
      timeOnly: format === 'time',
      dateOnly: format === 'date',
    };

    return getDateRenderer(field, this.timeZone, info, opts);
  }
}
